$(document).ready(function () {
  document.addEventListener('wpcf7mailsent', function () {
    $("#questions-form").hide();
    $("#questions-thank").removeClass("d-none");
    $("#questions-thank").addClass("d-flex");
    const elementCoordinates = document.querySelector('#questions-thank'). getBoundingClientRect();
    const windowScroll = window.pageYOffset || document.documentElement.scrollTop;
    window.scrollTo({
      top: elementCoordinates.top + windowScroll - 150,
      behavior: "smooth",
    });

  }, false);
  
  // custom scripts
  const mobileBurger = document.querySelector(".mobile-burger");
  const mobileMenu = document.querySelector(".mobile-menu");
  const body = document.querySelector("body");
  
  $('#mobile-btn').on( "click", function() {
    mobileBurger.classList.toggle("active");
    mobileMenu.classList.toggle("open");
    body.classList.toggle("over-hid");
  } );
  
  const onRemoveMobileMenu = () => {
    mobileBurger.classList.remove("active");
    mobileMenu.classList.remove("open");
    body.classList.remove("over-hid");
  };
  
  const anchors = [].slice.call(document.querySelectorAll('a[href*="#"], a[href*="/#"]')),
    animationTime = 300,
    framesCount = 20,
    marginTop = 150;
  
  anchors.forEach(function (item) {
    item.addEventListener("click", function (e) {
      let href = item.getAttribute("href");
      if(href[0] === '/' && href[1] === '#') {
          href = href.slice(1);
      }
      if(document.querySelector(href)) {
        e.preventDefault();
        
        let coordY =
          document.querySelector(href).getBoundingClientRect()
            .top +
          window.pageYOffset -
          marginTop;
    
        let scroller = setInterval(function () {
          let scrollBy = coordY / framesCount;
    
          if (
            scrollBy > window.pageYOffset - coordY &&
            window.innerHeight + window.pageYOffset < document.body.offsetHeight
          ) {
            window.scrollBy(0, scrollBy);
          } else {
            window.scrollTo(0, coordY);
            clearInterval(scroller);
          }
        }, animationTime / framesCount);
        onRemoveMobileMenu();
      }
    });
  });
  
  new Swiper(".mySwiper2", {
    slidesPerView: 5,
    breakpoints: {
      0: {
        slidesPerView: 3,
        slideToClickedSlide: true,
        spaceBetween: 24,
      },
      576: {
        slidesPerView: 4,
        slideToClickedSlide: true,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 5,
        slideToClickedSlide: true,
        spaceBetween: 24,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
    spaceBetween: 40,
  });
  
  const gallery = new Swiper(".mySwiper", {
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    slidesPerView: 2.2,
    spaceBetween: 21,
    breakpoints: {
      0: {
        slidesPerView: 1.6,
        slideToClickedSlide: true,
      },
      500: {
        slidesPerView: 1.8,
        slideToClickedSlide: true,
      },
      768: {
        slidesPerView: 2.1,
      },
      1200: {
        slidesPerView: 2.2,
      },
    },
    loop: true,
    navigation: {
      nextEl: ".swiper-next-custom",
    },
  });
  
  const totalSlides = gallery.slides.length - 2;
  const swiperLength = document.querySelector(".swiper-slide-length");
  const swiperCount = document.querySelector(".swiper-slide-count");
  
  if (swiperLength) {
      swiperLength.innerHTML = gallery.slides.length;
      
      gallery.on("slideChange", function (e) {
        swiperLength.innerHTML = e.slides.length;
        swiperCount.innerHTML = e.realIndex + 1;
      });
  }
  
  class ItcTabs {
    constructor(target, config) {
      const defaultConfig = {};
      this._config = Object.assign(defaultConfig, config);
      this._elTabs =
        typeof target === "string" ? document.querySelector(target) : target;
      this._elButtons = this._elTabs.querySelectorAll(".tabs__btn");
      this._elPanes = this._elTabs.querySelectorAll(".tabs__pane");
      this._eventShow = new Event("tab.itc.change");
      this._init();
      this._events();
    }
    _init() {
      this._elTabs.setAttribute("role", "tablist");
      this._elButtons.forEach((el, index) => {
        el.dataset.index = index;
        el.setAttribute("role", "tab");
        this._elPanes[index].setAttribute("role", "tabpanel");
      });
    }
    show(elLinkTarget) {
      const elPaneTarget = this._elPanes[elLinkTarget.dataset.index];
      const elLinkActive = this._elTabs.querySelector(".tabs__btn_active");
      const elPaneShow = this._elTabs.querySelector(".tabs__pane_show");
      if (elLinkTarget === elLinkActive) {
        return;
      }
      elLinkActive ? elLinkActive.classList.remove("tabs__btn_active") : null;
      elPaneShow ? elPaneShow.classList.remove("tabs__pane_show") : null;
      elLinkTarget.classList.add("tabs__btn_active");
      elPaneTarget.classList.add("tabs__pane_show");
      this._elTabs.dispatchEvent(this._eventShow);
      elLinkTarget.focus();
    }
    showByIndex(index) {
      const elLinkTarget = this._elButtons[index];
      elLinkTarget ? this.show(elLinkTarget) : null;
    }
    _events() {
      this._elTabs.addEventListener("click", (e) => {
        const target = e.target.closest(".tabs__btn");
        if (target) {
          e.preventDefault();
          this.show(target);
        }
      });
    }
  }
  if (document.querySelector(".plans")) {
    new ItcTabs(".plans");
  }
  
  if (document.querySelector(".map")) {
    var map = L.map("map").setView([59.4138294, 24.6600065], 18);
    
    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png?api_key=551be717-dc82-4fca-9a5d-be7838ae7e5d"
    ).addTo(map);
    
    var greenIcon = L.icon({
      iconUrl: "/wp-content/themes/moow/assets/images/map2.png",
    
      iconSize: [60, 60],
    });
    
    L.marker([59.4138294, 24.6600065], { icon: greenIcon }).addTo(map);
  }
});